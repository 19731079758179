import { useEffect, useMemo, useState } from "react";
import {
	Modal,
	useToast,
	Label,
	Tag,
	ProgressLoader,
	Button,
	Checkbox,
	ToggleSwitch,
} from "@withjuly/solisv2";
import { FormProvider } from "react-hook-form";
import { useZodForm } from "~/utils/hooks/zod-form";
import {
	AgencyBillingError,
	InviteTalentSchema,
	PhylloError,
} from "@withjuly/fabric";
import { trpc } from "../Utility/trpc";
import { useAuth } from "~/utils/context/auth";
import { ZodInput } from "../Input/ZodInput";
import { CaretDown } from "@withjuly/julycons/fill";
import {
	ArrowSquareOut,
	Check,
	Copy,
	LinkSimple,
	Plugs,
} from "@withjuly/julycons/bold";
import { useAgency } from "~/utils/context/agency";
import { useRouter } from "next/router";
import { cx } from "@withjuly/frontend-common";

interface CheckState {
	checked: boolean;
	auth: boolean;
	page: Page;
}
type Page = "no-auth" | "non-exclusive" | undefined;

export const InviteTalentModal = ({
	isOpen,
	alreadyChecked,
	setIsOpen,
	setTimer,
	continueChecked,
}: {
	isOpen: boolean;
	alreadyChecked: CheckState;
	setIsOpen: (isOpen: boolean) => void;
	setTimer: () => void;
	continueChecked: (checkState: CheckState) => void;
}) => {
	const { user } = useAuth();
	const { agencyProfile } = useAgency();
	const router = useRouter();
	const [hasCopied, setHasCopied] = useState<boolean>(false);
	const [showMoreOptions, setShowMoreOptions] = useState<boolean>(false);
	const [page, setPage] = useState<Page>(
		alreadyChecked.checked ? alreadyChecked.page : undefined,
	);

	useEffect(() => {
		if (alreadyChecked.checked) {
			continueChecked({ checked: false, auth: true, page: undefined });
		}
	}, [alreadyChecked, continueChecked]);

	const agencyMembership = useMemo(() => {
		if (!user || !agencyProfile) {
			return undefined;
		}
		return user.agencies.find((agency) => {
			return agency.agency.uuid === agencyProfile.uuid;
		});
	}, [user, agencyProfile]);

	if (page !== undefined) {
		return (
			<AddAccountModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				continueChecked={continueChecked}
				setTimer={setTimer}
				alreadyChecked={alreadyChecked}
				page={page}
			/>
		);
	}

	return (
		<Modal.Root isOpen={isOpen} setIsOpen={setIsOpen} size="lg">
			<Modal.Header title="Share this link with talent:" />
			<Modal.Body>
				<div className="flex flex-col gap-8">
					<div className="flex flex-col gap-4">
						<div className="bg-surface-quaternary flex items-center justify-between rounded-xl py-3 pl-6 pr-3">
							<p className="text-paragraph-md font-repro">
								app.withjuly.com/join/{agencyProfile?.username.toLowerCase()}
							</p>
							<Button
								size="sm"
								variant="secondary"
								leadingIcon={hasCopied ? Check : Copy}
								onClick={() => {
									navigator.clipboard
										.writeText(
											`app.withjuly.com/join/${agencyProfile?.username.toLowerCase()}`,
										)
										.then(() => {
											setHasCopied(true);
											setTimeout(() => {
												setHasCopied(false);
											}, 1000);
										});
								}}
							>
								{hasCopied ? "Copied" : "Copy"}
							</Button>
						</div>
						<div className="flex flex-col">
							<div
								className="flex h-10 w-[240px] cursor-pointer items-center gap-2 px-4"
								onClick={() => setShowMoreOptions(!showMoreOptions)}
							>
								<p className="text-button-sm font-repro">
									Other onboarding options
								</p>
								<CaretDown
									className={cx(
										"h-[14px] w-[14px]",
										showMoreOptions && "rotate-180 transform",
									)}
								/>
							</div>
							{showMoreOptions ? (
								<div className="mt-4 flex flex-col gap-4">
									<div className="border-stroke-tertiary flex justify-between rounded-2xl border p-4">
										<div className="flex flex-col gap-1">
											<div className="flex gap-2">
												<p className="text-button-md font-repro">No Auth</p>
												<Tag text="$12/month per talent" color="brand" />
											</div>
											<p className="text-text-secondary text-paragraph-sm font-repro">
												Add talent without requiring them to link socials.
											</p>
										</div>
										<Button
											variant="secondary"
											onClick={() => setPage("no-auth")}
										>
											Continue
										</Button>
									</div>
									<div className="border-stroke-tertiary flex justify-between rounded-2xl border p-4">
										<div className="flex flex-col gap-1">
											<div className="flex gap-2">
												<p className="text-button-md font-repro">
													Non-Exclusive
												</p>
												<Tag text="$2/month per talent" color="brand" />
											</div>
											<p className="text-text-secondary text-paragraph-sm font-repro">
												Add talent you don’t exclusively represent.
											</p>
										</div>
										<Button
											variant="secondary"
											onClick={() => setPage("non-exclusive")}
										>
											Continue
										</Button>
									</div>
								</div>
							) : null}
						</div>
					</div>

					{(!agencyProfile?.onboardingForm ||
						agencyProfile?.onboardingForm.length === 0) &&
					agencyMembership?.role === "owner" ? (
						<div
							className="border-stroke-secondary flex flex-col gap-6 rounded-2xl border p-6"
							style={{
								background: "linear-gradient(180deg, #202830 0%, #12171C 100%)",
							}}
						>
							<div className="flex flex-col gap-3">
								<p className="text-header-lg font-repro">
									Want to add a talent survey?
								</p>
								<p className="text-text-secondary text-paragraph-md font-repro">
									July makes it easy to collect important information from your
									talent, like deal preferences and location. Talent can be
									searched based on survey answers.
								</p>
							</div>
							<div className="flex justify-end gap-2">
								<a
									target="_blank"
									href="https://www.loom.com/share/13a1ebfa486b411da28738e1dafd3448?sid=1b0e2eb4-1d6e-4335-ae52-a35cf853314d"
								>
									<Button variant="blank" trailingIcon={ArrowSquareOut}>
										Watch a video demo
									</Button>
								</a>
								<Button
									variant="secondary"
									onClick={() =>
										router.push(`/agency/settings?openPage=onboard-survey`)
									}
								>
									Create survey
								</Button>
							</div>
						</div>
					) : null}
				</div>
			</Modal.Body>
		</Modal.Root>
	);
};

interface AddAccountModalProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	setTimer: () => void;
	continueChecked: (checkState: CheckState) => void;
	alreadyChecked: CheckState;
	page: Page;
}

const AddAccountModal: React.FC<AddAccountModalProps> = ({
	isOpen,
	setIsOpen,
	setTimer,
	continueChecked,
	alreadyChecked,
	page,
}) => {
	const { user } = useAuth();
	const utils = trpc.useContext();
	const invite = trpc.agency.inviteTalent.useMutation();
	const noAuthInvite = trpc.agency.inviteNoAuthTalent.useMutation();
	const { toast } = useToast();
	const [checked, setChecked] = useState<boolean>(alreadyChecked.checked);
	const [isConnecting, setIsConnecting] = useState(false);
	const [sendOnboardingSurvey, setSendOnboardingSurvey] =
		useState<boolean>(false);

	const form = useZodForm({
		schema: InviteTalentSchema,
		values: {
			firstName: "",
			lastName: "",
			displayName: null,
			email: "",
			username: "",
			managerEmail: user?.email ?? "",
			exclusive: page === "no-auth",
			isAuth: page === "no-auth" ? false : alreadyChecked.auth,
			sendOnboardingSurvey: false,
			platformUsernames: {
				instagramUsername: "",
				youtubeUsername: "",
				tiktokUsername: "",
			},
		},
		submit: async (values, { onFormError }) => {
			if (values.isAuth) {
				invite.mutateAsync(
					{
						...values,
						isAuth: true,
					},
					{
						onSuccess: () => {
							setIsOpen(false);
							setIsConnecting(false);
							utils.agency.getCreatorsPreview.invalidate();
							toast({
								title: "Invite link sent",
								description: `July sent an invite link to ${values.email}.`,
								variant: "success",
							});
							form.reset();
							if (checked) {
								setTimer();
							}

							continueChecked({
								checked: checked,
								auth: true,
								page: "non-exclusive",
							});
						},
						onError: (error) => {
							const parsedError =
								AgencyBillingError.AgencyBillingErrorSchema.safeParse(
									error.data,
								);

							if (parsedError.success) {
								const error = parsedError.data;
								toast({
									variant: "error",
									title: error.message,
									description: error.description,
								});
							} else {
								toast({
									variant: "error",
									title: "Error inviting talent",
									description:
										"There was an error inviting your new talent. Please try again later.",
								});
							}

							setIsConnecting(false);
							onFormError(error);
						},
					},
				);
			} else {
				noAuthInvite.mutateAsync(
					{
						...values,
						isAuth: false,
						sendOnboardingSurvey: sendOnboardingSurvey,
					},
					{
						onSuccess: () => {
							setIsOpen(false);
							setIsConnecting(false);
							utils.agency.getCreatorsPreview.invalidate();
							toast({
								title: "Talent added",
								description: `${values.firstName} has been added to your roster.`,
								variant: "success",
							});
							form.reset();
							if (checked) {
								setTimer();
							}

							continueChecked({
								checked: checked,
								auth: checked ? false : true,
								page: fields.exclusive ? "no-auth" : "non-exclusive",
							});
						},
						onError: (error) => {
							const parsedError =
								AgencyBillingError.AgencyBillingErrorSchema.safeParse(
									error.data,
								);

							const phylloError = PhylloError.PhylloErrorSchema.safeParse(
								error.data,
							);

							if (parsedError.success) {
								const error = parsedError.data;
								toast({
									variant: "error",
									title: error.message,
									description: error.description,
								});
							} else if (phylloError.success) {
								const error = phylloError.data;
								toast({
									variant: "error",
									title: error.message,
									description: error.description,
								});
							} else {
								onFormError(error);
							}

							setIsConnecting(false);
						},
					},
				);
			}
		},
		mode: "onBlur",
	});

	const getHeader = () => {
		if (page === "no-auth") {
			return <Modal.Header title="Add with No Auth" />;
		} else {
			return <Modal.Header title="Add Non-Exclusive Talent" />;
		}
	};

	const getBody = () => {
		if (page === "no-auth") {
			return (
				<div className="flex flex-col gap-3">
					<p className="text-paragraph-md font-repro">
						No Auth stats are updated every 1-2 weeks. Instagram Stories,
						Reports and other features may be unavailable.
					</p>
					<a
						target="_blank"
						href="https://withjuly.com/agency-resources/no-auth"
					>
						<Button variant="blank" trailingIcon={ArrowSquareOut} size="sm">
							Learn more
						</Button>
					</a>
				</div>
			);
		} else {
			return (
				<div className="flex flex-col gap-4">
					<p className="text-paragraph-md font-repro">
						Non-exclusive talent feature a ‘Non-Exclusive’ badge on all assets.
					</p>
					<div className="bg-surface-quaternary border-stroke-tertiary flex flex-col gap-4 rounded-xl border p-4">
						<div className="flex items-center gap-4">
							<div className="flex">
								<ToggleSwitch
									toggle={fields.isAuth}
									onToggle={() => form.setValue("isAuth", !fields.isAuth)}
								/>
							</div>
							<Label size="md" variant="paragraph" color="primary">
								Require talent to connect socials{" "}
							</Label>
						</div>
						{!fields.isAuth ? (
							<Tag text="You will pay $12/month per talent" color="brand" />
						) : (
							<Tag text="You will pay $2/month per talent" color="brand" />
						)}
						{!fields.isAuth ? (
							<div className="flex flex-col gap-4">
								<p className="text-paragraph-md font-repro">
									No Auth stats are updated every 1-2 weeks. Instagram Stories,
									Reports and other features may be unavailable.
								</p>
								<a
									target="_blank"
									href="https://withjuly.com/agency-resources/no-auth"
								>
									<Button
										variant="blank"
										trailingIcon={ArrowSquareOut}
										size="sm"
									>
										Learn more
									</Button>
								</a>
							</div>
						) : null}
					</div>
				</div>
			);
		}
	};

	const fields = form.watch();
	const areSocialsEmpty = () => {
		return (
			fields.platformUsernames.instagramUsername.length === 0 &&
			fields.platformUsernames.tiktokUsername.length === 0 &&
			fields.platformUsernames.youtubeUsername.length === 0
		);
	};

	return (
		<Modal.Root
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			size={isConnecting ? "md" : "lg"}
		>
			{!isConnecting ? getHeader() : null}
			<Modal.Body>
				{isConnecting ? (
					<div className="my-20 flex h-full flex-col items-center justify-center">
						<div className="bg-sky-alpha-4 mb-4 w-fit rounded-[8px] p-2">
							<Plugs className="text-brand h-6 w-6" />
						</div>
						<div className="mb-8 flex flex-col items-center justify-center gap-[2px]">
							<p className="text-header-lg font-repro">
								{fields.isAuth
									? "Sending invite"
									: areSocialsEmpty()
										? "Adding to roster"
										: "Linking socials"}
							</p>
							<p className="text-text-tertiary text-paragraph-sm font-repro">
								Just a few moments...
							</p>
						</div>
						<ProgressLoader />
					</div>
				) : (
					<div className="flex flex-col gap-6">
						{getBody()}
						<div className="bg-stroke-tertiary h-px w-full">
							{/** DIVIDER */}
						</div>

						<FormProvider {...form}>
							<form
								className="flex flex-col gap-6"
								onSubmit={(e) => e.preventDefault()}
							>
								<div className="flex items-center gap-3">
									<ZodInput
										name="firstName"
										label="First Name"
										placeholder="Add first name"
									/>
									<ZodInput
										name="lastName"
										label="Last Name"
										placeholder="Add last name"
									/>
								</div>

								<ZodInput
									name="displayName"
									label="Display name (Optional)"
									placeholder="Add display name"
									description="Add a display name if talent doesn't go by their actual name on social media."
								/>

								<ZodInput
									name="username"
									label="Set Username"
									placeholder="username"
									leadingIcon={() => <p>july.bio/</p>}
								/>

								<ZodInput
									name="email"
									label="Talent Email"
									placeholder="Add email address"
									description={
										fields.isAuth
											? "A link inviting talent to connect socials will be sent to this email."
											: "Notifications from July will be sent to this address"
									}
								/>

								{!fields.isAuth ? (
									<div className="flex gap-4">
										<Checkbox
											label="Send onboarding survey"
											isChecked={sendOnboardingSurvey}
											onChecked={setSendOnboardingSurvey}
										/>
										{sendOnboardingSurvey ? (
											<Tag
												text="Your talent will receive an email"
												color="brand"
											/>
										) : (
											<Tag
												text="Your talent won't receive an email"
												color="graphite"
											/>
										)}
									</div>
								) : null}

								<ZodInput
									name="managerEmail"
									label="Manager Email"
									placeholder="Add email address"
									description="Brands inquiring about talent will be directed here."
								/>

								{!fields.isAuth ? (
									<>
										<div className="bg-stroke-tertiary h-px w-full" />
										<div className="flex flex-col">
											<div className="flex items-center gap-[6px]">
												<LinkSimple className="h-[14px] w-[14px]" />
												<p className="text-button-sm font-repro">
													Link accounts
												</p>
											</div>
											<p className="text-paragraph-sm text-text-secondary font-repro">
												Only add profiles you want to display. Stats will be
												collected within 24 hours
											</p>
										</div>
										<ZodInput
											name="platformUsernames.instagramUsername"
											label="Instagram"
											placeholder="@username"
										/>
										<ZodInput
											name="platformUsernames.tiktokUsername"
											label="TikTok"
											placeholder="@username"
										/>
										<ZodInput
											name="platformUsernames.youtubeUsername"
											label="Youtube"
											placeholder="Link to YouTube channel"
										/>
									</>
								) : null}
							</form>
						</FormProvider>
					</div>
				)}
			</Modal.Body>
			{!isConnecting ? (
				<Modal.Footer
					checkLabel="Reopen after adding"
					isChecked={checked}
					onChecked={(checked) => {
						setChecked(checked);
					}}
					primaryLabel={fields.isAuth ? "Send invite link" : "Add to roster"}
					onPrimaryClicked={() => {
						setIsConnecting(true);
						form.onSubmit();
					}}
					buttons="primary-check"
					layout="separated"
					isPrimaryDisabled={!form.formState.isValid}
				/>
			) : null}
		</Modal.Root>
	);
};
